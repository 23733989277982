
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@use '../m3-theme.scss' as m3-theme;


// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$magenta-palette,
      tertiary: mat.$violet-palette,
    ),
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.


html {
  @include mat.all-component-themes(m3-theme.$light-theme);

  background: mat.get-theme-color(m3-theme.$light-theme, surface);

  &.dark {
    @include mat.all-component-colors(m3-theme.$dark-theme);

    background: mat.get-theme-color(m3-theme.$dark-theme, surface);
  }
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.

// Comment out the line below if you want to use the deprecated `color` inputs.
@tailwind base;
@tailwind components;
@tailwind utilities;



html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@layer components {
  .responsive-dialog {
    @apply w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4;
  }
}

@layer components {
  .responsive-dialog-image {
    @apply w-full ;
  }
}

